import FooterLogo from "../Images/footer-logo.png"
import { useTranslation } from "react-i18next"

const AppFooter = () => {
  const { t } = useTranslation("translation", { keyPrefix: "footer" });
  return (
    <div className="footer-div" id="footer">
      <div className="footer-content-div">
        <img src={FooterLogo} />
      </div>
      <div className="footer-content-div">
        <div className="footer-content-text-title">
          <span>{t("contact")}</span>
        </div>
        <div className="footer-content-text-content contact-content">
          <span>{t("email-title")}</span>
          <span>dudustudio2023@gmail.com</span>
        </div>
      </div>
      <div className="footer-content-div">
        <div className="footer-content-text-title">
          <span>{t("services")}</span>
        </div>
        <div className="footer-content-text-content service-content">
          <span>{t("service-items.sysdev")}</span>
          <span>{t("service-items.brand-design")}</span>
          <span>{t("service-items.photograph")}</span>
          <span>{t("service-items.business-support")}</span>
        </div>
      </div>
    </div>
  );
};

export default AppFooter;