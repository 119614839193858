import useRWD from '../Hook/useRWD';
import design from '../Images/design.png';
import development from '../Images/development.png';
import marketing from '../Images/marketing.png';
import photography from '../Images/photography.png';
import DU from '../Images/DU.png';
import DU_CH from '../Images/DU_CH.png';
import { useTranslation } from "react-i18next"
const AppServiceSectionWorkItems = () => {
  const device = useRWD()
  const { t } = useTranslation("translation", { keyPrefix: "service.work-items" });

  return (
    <div className='service-page-div'>
      <div className='service-page-text'>
        <div className='service-page-text-title'>
          <span>{t("title")}</span>
        </div>
        <div className='service-page-text-context'>
          <span>{t("desc")}</span>
        </div>
      </div>
      <div className="service-page-service">
        <div className="grid">
          <div className={device === 'mobile' ? "col-20 service-page-service-grid" : "col-10 service-page-service-grid"} >
            <div className='flex' style={{ backgroundImage: `url(${DU_CH})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center' }}>
              <img src={development} />
              <div className='service-page-service-item'>
                <p className='service-page-service-title'>{t("topic.sysdev.title")}</p>
                <span className='service-page-service-text'>{t("topic.sysdev.desc")}</span>
              </div>
            </div>
          </div>
          <div className={device === 'mobile' ? "col-20 service-page-service-grid" : "col-10 service-page-service-grid"}>
            <div className='flex'>
              <img src={photography} />
              <div className='service-page-service-item'>
                <p className='service-page-service-title'>{t("topic.photograph.title")}</p>
                <span className='service-page-service-text'>{t("topic.photograph.desc")}</span>
              </div>
            </div>
          </div>
          <div className={device === 'mobile' ? "col-20 service-page-service-grid" : "col-10 service-page-service-grid"}>
            <div className='flex'>
              <img src={design} />
              <div className='service-page-service-item'>
                <p className='service-page-service-title'>{t("topic.brand-design.title")}</p>
                <span className='service-page-service-text'>{t("topic.brand-design.desc")}</span>
              </div>
            </div>
          </div>
          <div className={device === 'mobile' ? "col-20 service-page-service-grid" : "col-10 service-page-service-grid"}>
            <div className='flex' style={{ backgroundImage: `url(${DU})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center' }}>
              <img src={marketing} />
              <div className='service-page-service-item'>
                <p className='service-page-service-title'>{t("topic.business-support.title")}</p>
                <span className='service-page-service-text'>{t("topic.business-support.desc")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppServiceSectionWorkItems;