import AppHomePage from "./Components/AppHomePage";
import AppService from "./Components/AppService";
import AppToolbar from "./Components/AppToolbar";
import AppAchievements from "./Components/AppAchievements";
import AppServiceSectionPricing from "./Components/AppPricing";
import AppFooter from "./Components/AppFooter";
import './Style/main.scss'
import AppContactMe from "./Components/AppContactMe";

const App = () => {
    return (
        <div>
            <AppToolbar />
            <AppHomePage />
            <AppService />
            <AppAchievements />
            <AppServiceSectionPricing />
            <AppContactMe />
            <AppFooter />
        </div>
    )
}

export default App;