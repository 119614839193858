import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import zh_tw from "./zh_tw.json"
import zh_tw_pricing from "./zh_tw-pricing.json"

const resources = {
  "zh": {
    "translation": zh_tw,
    "pricing-detail": zh_tw_pricing
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    debug: true,
    lng: "zh",            // default.
    fallbackLng: "zh",
    interpolation: {
      escapeValue: false, // XSS handled by React.
    },
  });

export default i18n;