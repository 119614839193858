import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
import achievements_1 from '../Images/政府專案.png';
import achievements_2 from '../Images/系統設計.png';
import achievements_3 from '../Images/介面設計.png';
import achievements_4 from '../Images/訂單系統.png';
import useRWD from '../Hook/useRWD';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const AppAchievements = () => {
  const device = useRWD()
  const { t } = useTranslation("translation", { keyPrefix: "service.achievements" });
  const responsiveOptions = [
    {
      image: achievements_1,
    },
    {
      image: achievements_2,
    },
    {
      image: achievements_3,
    },
    {
      image: achievements_4,
    },
  ];
  return (
    <section id='achievements' >
      <div className='achievements-page-div'>
        <div className='achievements-page-text'>
          <div className='achievements-page-text-title'>
            <span>{t("title")}</span>
          </div>
        </div>
        <Swiper
          cssMode={true}
          navigation={true}
          pagination={true}
          mousewheel={true}
          keyboard={true}
          loop={true}
          modules={[Navigation, Pagination, Mousewheel, Keyboard]}
          className="mySwiper"          
          style={{margin: device =='mobile'?"0":"0 15rem"}}
        >
          {
            responsiveOptions.map((respons) => (
              <SwiperSlide>
                <img src={respons.image} style={{width:'100%'}}/>
              </SwiperSlide>
            ))
          }
        </Swiper>
      </div>
    </section>
  );
};

export default AppAchievements;