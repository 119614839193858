const AppServiceSectionPricingDetails = (param) => {
  console.log(param)
  const {title, price, manifest} = param;
  return (
    <div className="pricing-details-div">
      <div className="pricing-details-head-div">
        <div className="pricing-details-head-text-title">
          <span>{title}</span>
        </div>
        <div className="pricing-details-head-text-price">
          <span>{price}</span>
        </div>
      </div>
      <ul className="pricing-details-content-ul">
        {manifest ? manifest.map((item, i) => (
          <li key={i}>
            <span>{item}</span>
          </li>
        )) : (null)}
      </ul>
    </div>
  );
};

export default AppServiceSectionPricingDetails;