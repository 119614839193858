import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { useTranslation } from "react-i18next"
import logo from '../Images/top-logo.png';
const AppToolbar = () => {
    const { t } = useTranslation("translation", { keyPrefix: "toolbar" });
    const scrollIntoView = (section) => {
        const element = document.getElementById(section);
        const yOffset = -120;
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
    }
    const start = (
        <div className='toolbar-div-button'>
            <Button label={t("work-items")} severity="secondary" size="large" text onClick={() => { scrollIntoView('service') }} />
            <Button label={t("achievements")} severity="secondary" size="large" text onClick={() => { scrollIntoView('achievements') }} />
        </div>
    )
    const center = (
        <img src={logo} style={{ cursor: 'pointer' }} onClick={() => { scrollIntoView('homepage') }} />
    )
    const end = (
        <div className='toolbar-div-button'>
            <Button label={t("pricing")} severity="secondary" size="large" text onClick={() => { scrollIntoView('pricing') }} />
            <Button label={t("contact")} severity="secondary" size="large" text onClick={() => { scrollIntoView('contact') }}/>
        </div>
    )
    return (
        <Toolbar className='toolbar' start={start} center={center} end={end} />
    )
}

export default AppToolbar;