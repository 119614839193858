import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next"
import { Card } from 'primereact/card';
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from 'primereact/button';
import { Messages } from 'primereact/messages';
import emailjs from '@emailjs/browser';
import useRWD from "../Hook/useRWD";

const AppContactMe = () => {
    const [name, setName] = useState("");
    const [mail, setMail] = useState("");
    const [message, setMessage] = useState("");
    const msgs = useRef(null);
    const device = useRWD();

    const { t } = useTranslation("translation", { keyPrefix: "service.contact" });

    const sendMessageOnClick = () => {
        let data = {
            name: name,
            mail: mail,
            message: message
        }
        emailjs.send('service_7x45zsh', 'template_j61vsql', data, 'B2-t6YTwpdVNZfqdi')
            .then((result) => {
                msgs.current.show(
                    { sticky: true, severity: 'success', summary: '寄送成功', detail: '感謝您的聯絡，我們將會盡快回復您。' }
                );
            }, (error) => {
                msgs.current.show(
                    { sticky: true, severity: 'error', summary: '寄送失敗', detail: '發生一點錯誤，請稍後再試。' }
                );
            });
    }

    return (
        <section id='contact'>
            <div className='service-page-div'>
                <div className='service-page-text' >
                    <div className='service-page-text-title'>
                        <span>{t("title")}</span>
                    </div>
                </div>
                <div className='justify-content-center flex '>
                    <Card className={device === 'PC' ? "w-5 mb-6" : "w-full mb-6"} title={t("card.title")}>
                        <Messages ref={msgs} />
                        <div className="flex flex-column gap-2 justify-content-cente p-2">
                            <label htmlFor="name">{t("card.name")}</label>
                            <InputText id="name" className="w-full" value={name} onChange={(e) => setName(e.target.value)} />
                        </div>

                        <div className="flex flex-column gap-2 justify-content-cente p-2">
                            <label htmlFor="mail">{t("card.mail")}</label>
                            <InputText id="mail" className="w-full" value={mail} onChange={(e) => setMail(e.target.value)} />
                        </div>

                        <div className="flex flex-column gap-2 justify-content-cente p-2">
                            <label htmlFor="message">{t("card.message")}</label>
                            <InputTextarea id="message" className="w-full" rows={5} value={message} onChange={(e) => setMessage(e.target.value)} />
                        </div>
                        <div className="flex flex-column gap-2 justify-content-cente p-2">
                            <Button className="w-full" label={t("card.submit")} raised onClick={sendMessageOnClick} />
                        </div>

                    </Card>
                </div>
            </div>
        </section>
    )
}

export default AppContactMe;