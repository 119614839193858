import HomepageLogo from '../Images/Mask group.png';
import { useTranslation } from "react-i18next"
const AppHomePage = () => {
    const { t } = useTranslation("translation", { keyPrefix: "homepage" });
    return (
        <section id="homepage">
            <div className='home-page-div'>
                <div className='home-page-text'>
                    <div className='home-page-text-title'>
                        <span >{t("title")}</span>
                    </div>
                    <div className='home-page-text-context'>
                        <span >{t("desc")} </span>
                    </div>
                </div>
                <img src={HomepageLogo} />
                <div className='home-page-divider'></div>
            </div>
        </section>
    )
}

export default AppHomePage