import useRWD from "../Hook/useRWD";
import AppServiceSectionPricingDetails from "./AppPricingDetails"
import { useTranslation } from "react-i18next"
const AppServiceSectionPricing = () => {
  const { t } = useTranslation("translation", { keyPrefix: "service.pricing" });
  const pricing_items = useTranslation("pricing-detail").t("pricing", { returnObjects: true });
  const device = useRWD();
  return (
    <section id='pricing'>
      <div className='service-page-div'>
        <div className='service-page-text'>
          <div className='service-page-text-title'>
            <span>{t("title")}</span>
          </div>
        </div>
        <div className="service-pricing-details-content-div">
          <div className="service-pricing-details-content-shadow" />
          <div className="grid">
            <div className="col-1" style={{ display: device !== 'PC' ? 'none' : 'block' }}></div>
            {pricing_items.map((item, i) => (
              <div className={device !== 'PC' ? "col-20" : "col-6"} style={{ justifyContent: 'center', display: 'flex' }}>
                <AppServiceSectionPricingDetails
                  key={i}
                  title={item.title}
                  price={item.price}
                  manifest={item.manifest}
                />
              </div>
            ))}
            <div className="col-1" style={{ display: device !== 'PC' ? 'none' : 'block' }}></div>
          </div>
        </div>
      </div>
    </section>

  );
};

export default AppServiceSectionPricing;