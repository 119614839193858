import React from 'react';
import ReactDOM from 'react-dom';
import * as ReactDOMClient from 'react-dom/client';
import "./I18n/i18n"
import './index.css';
import * as serviceWorker from './serviceWorker';
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import App from './App';

const container = document.getElementById('root')!;

// Create a root.
const root = ReactDOMClient.createRoot(container);

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

serviceWorker.unregister();